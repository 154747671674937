<template>
  <div>
  <EditCategory
    @editDetail="editCategory"
    @cancelEdit="cancelEdit"
    @updateSlugAlreadyExists="updateSlugAlreadyExists"
    :reportingCategoryId="reportingCategoryId"
    :slugAlreadyExists="slugAlreadyExists"
  />
  </div>
</template>

<script>
  import EditCategory from "../../../components/reporting-category/edit";

  export default {
    name: 'Edit-Category',
    components:{
      EditCategory,
    },
    data: () => ({
      categoryId: '',
      slugAlreadyExists: false,
    }),
    methods:{
      async editCategory(data){
        try {
          let value = {
            id: this.reportingCategoryId,
            info: data
          };
          const res = await this.$store.dispatch("reportingCategory/updateCategoryDetails", value);
          this.$vs.notify({
            title: "Reporting Category Edit",
            text: " Reporting Category Updated successfully",
            color: "success",
          });
          this.slugAlreadyExists = false;
          this.$vs.loading.close();
          this.backToList()
        } catch (error) {
          this.$vs.notify({
            title: "Reporting Category Edit",
            text: `${e.data.message}`,
            color: "danger",
          });
          this.slugAlreadyExists = true;
        }
      },
      cancelEdit() {
        this.backToList()
      },
      backToList() {
        this.$router.push({ name: "CategoryManagement", query: {
          activeTab: 1
        }});
      },
      updateSlugAlreadyExists() {
        this.slugAlreadyExists = false;
      },
    },
    async created() {
      this.reportingCategoryId = this.$route.params.reportingCategoryId;
    }
  };
</script>
